import React, { FC, ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Linq } from '@src/components/Linq'
import { shoppingAiRoute } from '@src/routes'
import { sendEvent } from '@utils/analytics/coreAnalytics'

type ShoppingAiBannerProps = {
  alertMessage: ReactNode
  actionButtonText?: ReactNode
  gaSection?: string
}

const useStyles = makeStyles((theme) => ({
  actionButton: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(2, 6),
  },
  alertBanner: {
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.black,
    justifyContent: 'center',
    padding: theme.spacing(3, 0),
    textAlign: 'center',
  },
}))

export const ShoppingAiBanner: FC<ShoppingAiBannerProps> = ({
  actionButtonText,
  alertMessage,
  gaSection,
}) => {
  const classes = useStyles()

  return (
    <Alert
      className={classes.alertBanner}
      data-testid="shopping-ai-banner"
      icon={false}
      variant="outlined"
    >
      {alertMessage}
      <Linq
        data-testid="shopping-ai-banner-action-button"
        href={shoppingAiRoute.index()}
      >
        <Button
          className={classes.actionButton}
          color="primary"
          variant="outlined"
          onClick={() => {
            sendEvent({
              payload: {
                event: 'ls_ai_interaction',
                action: 'open ai tool',
                detail: undefined,
                section: gaSection,
                component: 'body',
                element_type: 'button',
                element_text: actionButtonText || 'try it out',
                link_url: shoppingAiRoute.index(),
                core_event: 'no',
                user_detail: undefined,
                event_group: undefined,
                event_subgroup: undefined,
                product_id: undefined,
                product_brand: undefined,
              },
            })
          }}
        >
          {actionButtonText || (
            <FormattedMessage id="TRY_IT_OUT" defaultMessage="Try It Out" />
          )}
        </Button>
      </Linq>
    </Alert>
  )
}

export default ShoppingAiBanner
