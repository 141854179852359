import React, { useState } from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme, FormControlLabel, Checkbox, Button } from '@material-ui/core'
import { ShippingTypes } from '@src/utils/searchUtils'
import ShipsTodayIcon from '@src/icons/ShipsTodayIcon'
import FreeFreightIcon from '@src/icons/FreeFreightIcon'
import CheckMarkIcon from '@src/icons/CheckMarkIcon'
import CaretDownIcon from '@icons/CaretDownIcon'
import HandleMarkup from '@src/components/HandleMarkup'
import { FacetSelection, SelectableFacet } from './useCategoryProductSearch'
import messages from '@utils/messages'
import facetMessages from '@utils/MessageSets/defineFacetMessages'
import { sendFacetChangeEvent, sendCSRFiltersEvent } from '@utils/analytics'
import { useRouter } from '@src/routes'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    facetOptionsContainer: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    facetOption: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
    checkboxRoot: {
      padding: theme.spacing(1, 2, 1, 0),
    },
    labelRoot: {
      width: '100%',
      margin: 0,
      alignItems: 'flex-start',
    },
    facetLabel: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: 4,
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(14),
        marginTop: 2,
      },
    },
    facetOptionLabel: {
      display: 'inline-flex',
      '& svg': {
        fontSize: theme.typography.pxToRem(28),
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1 / 2),
        color: theme.palette.secondary.main,
      },
      '& sup, sub': {
        verticalAlign: 'baseline',
        position: 'relative',
        top: '-0.4em',
      },
      '& sub': {
        top: '0.1em',
      },
    },
    checkboxIcon: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 3,
      width: 24,
      height: 24,
      fontSize: theme.typography.pxToRem(4),
      [theme.breakpoints.up('md')]: {
        width: 18,
        height: 18,
      },
    },
    checkboxIconChecked: {
      '& svg': {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    expandFacetOptionsButton: {
      fontSize: theme.typography.pxToRem(14),
      minWidth: 0,
      padding: 0,
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    expandFacetOptionsIcon: {
      fontSize: theme.typography.pxToRem(9),
      marginLeft: theme.spacing(1),
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginTop: theme.spacing(1 / 2),
    },
    iconExpanded: {
      transform: 'rotate(180deg)',
    },
  })
})

const renderShippingIcon = (facetOption: string) => {
  switch (facetOption) {
    case ShippingTypes.SameDay:
      return <ShipsTodayIcon />
    case ShippingTypes.FreeFreight:
      return <FreeFreightIcon color="secondary" />
    default:
      return null
  }
}

interface CSRFacetProps {
  loading: boolean
  facet: SelectableFacet
  handleUpdateFacets: (facet: FacetSelection) => void
  title: string
}

const CSRFacet: React.FC<CSRFacetProps> = ({
  loading,
  facet,
  handleUpdateFacets,
  title,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const showExpandOptionsTrigger = facet.numToDisplay < facet.options.length
  const visibleFacetOptions = expanded
    ? facet.options.length
    : facet.numToDisplay
  const showShippingIcon = facet.key === 'facet_shipping'
  const router = useRouter()
  const enFacetName =
    facetMessages[facet.key.toUpperCase()].defaultMessage || facet.key
  const facetsAndSearchTerm = (selectedTitle, selectedValue, sendSelected) => {
    const selectedFacet = `${selectedTitle}: ${selectedValue}`
    let previousSelectedFacetsArray = router.query.facets
      ? router.query.facets.split(',').map((eachFacet) => {
          const [title, value] = eachFacet.split(':')
          const formatTitle =
            facetMessages[title.toUpperCase()].defaultMessage || title
          return `${formatTitle}: ${value}`
        })
      : []
    const searchTerm = router.query.term || router.query.searched
    const keyword = searchTerm ? `keyword: ${searchTerm}` : ''

    if (previousSelectedFacetsArray.includes(selectedFacet)) {
      previousSelectedFacetsArray = previousSelectedFacetsArray.filter(
        (facet) => facet !== selectedFacet
      )
    }

    if (keyword && previousSelectedFacetsArray.length) {
      return sendSelected
        ? `${selectedFacet}  >> ${keyword} > ${previousSelectedFacetsArray.join(
            ' > '
          )}`
        : `${keyword} | ${previousSelectedFacetsArray.join(' | ')}`
    } else if (keyword && !previousSelectedFacetsArray.length) {
      return sendSelected ? `${selectedFacet}  >>  ${keyword}` : `${keyword}`
    } else if (!keyword && previousSelectedFacetsArray.length) {
      return sendSelected
        ? `${selectedFacet} >> ${previousSelectedFacetsArray.join(' > ')}`
        : previousSelectedFacetsArray.join(' | ')
    } else {
      return sendSelected ? selectedFacet : undefined
    }
  }

  return (
    <div className={classes.facetOptionsContainer}>
      {facet.options.slice(0, visibleFacetOptions).map((option, index) => {
        return (
          <div className={classes.facetOption} key={index}>
            <FormControlLabel
              classes={{
                root: classes.labelRoot,
                label: classes.facetLabel,
              }}
              disabled={loading}
              id={option.value}
              value={option.value}
              checked={!!option.selected}
              control={
                <Checkbox
                  data-testid={`csr-facet-${option.value}`}
                  id={option.value}
                  color="primary"
                  classes={{
                    root: classes.checkboxRoot,
                  }}
                  icon={<span className={classes.checkboxIcon} />}
                  checkedIcon={
                    <span
                      className={clsx(
                        classes.checkboxIcon,
                        classes.checkboxIconChecked
                      )}
                    >
                      <CheckMarkIcon />
                    </span>
                  }
                  onClick={() => {
                    const eventLabel = facetsAndSearchTerm(
                      title,
                      option.value,
                      true
                    )
                    handleUpdateFacets({
                      key: facet.key,
                      value: option.value,
                      prefix: facet.prefix,
                    })
                    sendFacetChangeEvent(eventLabel, !!option.selected)
                    sendCSRFiltersEvent({
                      event: 'filter_interaction',
                      action: !!option.selected
                        ? 'remove filter'
                        : 'add filter',
                      component: 'left rail',
                      elementType: 'checkbox',
                      elementText: `${option.value} (${option.count})`,
                      coreEvent: 'no',
                      filterName: option.value,
                      filterNameCount: `${option.count}`,
                      filterCategory: enFacetName,
                      filterExisting: facetsAndSearchTerm(
                        title,
                        option.value,
                        false
                      ),
                    })
                  }}
                />
              }
              label={
                <div className={classes.facetOptionLabel}>
                  <HandleMarkup value={`${option.value} (${option.count})`} />
                  {showShippingIcon && renderShippingIcon(option.value)}
                </div>
              }
            />
          </div>
        )
      })}
      {showExpandOptionsTrigger && (
        <Button
          className={classes.expandFacetOptionsButton}
          onClick={() => {
            setExpanded(!expanded)
            sendCSRFiltersEvent({
              event: 'filter_interaction',
              action: !!expanded ? 'show less filters' : 'show more filters',
              component: 'left rail',
              elementType: 'accordion',
              coreEvent: 'no',
              filterCategory: enFacetName,
              filterExisting: facetsAndSearchTerm(title, '', false),
            })
          }}
          data-testid="toggle-show-facet-options"
        >
          {expanded ? (
            <FormattedMessage {...messages.SHOW_LESS} />
          ) : (
            <FormattedMessage {...messages.SHOW_MORE} />
          )}
          <CaretDownIcon
            className={clsx(classes.expandFacetOptionsIcon, {
              [classes.iconExpanded]: expanded,
            })}
          />
        </Button>
      )}
    </div>
  )
}

export default CSRFacet
