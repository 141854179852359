import React from 'react'
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core'
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      display: 'inline-block',
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    headline: {
      marginBottom: theme.spacing(5),
    },
    subHeadline: {
      marginTop: theme.spacing(5),
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    facets: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: 120,
      },
    },
    search: {
      marginRight: theme.spacing(2),
    },
    sort: {
      marginLeft: theme.spacing(2),
    },
    headerRow: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 900,
    },
    tableRow: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      padding: theme.spacing(0, 5, 0, 0),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(5, 0),
      },
    },
    divider: {
      marginLeft: 0,
      border: 'none',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      width: '90%',
    },
    mGridText: {
      lineHeight: 0.5,
    },
    gridRowParent: {
      display: 'inline-flex',
      padding: theme.spacing(5),
    },
    gridRow: {
      border: `1px solid ${theme.palette.grey[400]}`,
      minHeight: 344,
      padding: theme.spacing(4),
      borderRadius: theme.typography.pxToRem(6),
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  })
)

enum ViewPreference {
  grid,
  list,
}

interface SkeletonProps {
  view: ViewPreference | null
}
const CSRSkeletonLoader: React.FC<SkeletonProps> = ({
  view = ViewPreference.list,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  return !isMobile ? (
    <>
      <Box mt={6}>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <Grid container>
              <Grid item sm={3} xs={6}>
                <Typography variant="h2">Products</Typography>
                {Array(4)
                  .fill({})
                  .map((_, j) => (
                    <React.Fragment key={j}>
                      <Skeleton
                        variant="text"
                        width="85%"
                        height={40}
                        className={classes.subHeadline}
                        animation={'wave'}
                      />
                      <hr className={classes.divider} />
                      {Array(5)
                        .fill({})
                        .map((_, i) => (
                          <Skeleton
                            variant="text"
                            width="50%"
                            height={30}
                            className={classes.facets}
                            animation={'wave'}
                            key={i}
                          />
                        ))}
                    </React.Fragment>
                  ))}
              </Grid>
              <Grid item sm={9} xs={6}>
                <Grid container>
                  <Grid item sm={3}>
                    <Skeleton
                      variant="text"
                      width="100%"
                      height={60}
                      className={classes.search}
                      animation={'wave'}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Skeleton
                      variant="text"
                      width="85%"
                      height={60}
                      className={classes.sort}
                      animation={'wave'}
                    />
                  </Grid>
                  <Grid item sm={3}></Grid>
                  <Grid item sm={3}>
                    <Skeleton
                      variant="text"
                      width="75%"
                      height={60}
                      className={classes.text}
                      animation={'wave'}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  {view === ViewPreference.list ? (
                    <>
                      <Grid item sm={2} className={classes.headerRow}>
                        Compare
                      </Grid>
                      <Grid item sm={3} className={classes.headerRow}>
                        Product Number
                      </Grid>
                      <Grid item sm={3} className={classes.headerRow}>
                        Product Name
                      </Grid>
                      <Grid item sm={2} className={classes.headerRow}>
                        Product Description
                      </Grid>
                      <Grid item sm={2} className={classes.headerRow}>
                        Pricing
                      </Grid>
                      {Array(20)
                        .fill({})
                        .map((_, j) => (
                          <React.Fragment key={j}>
                            <Grid item sm={2} className={classes.tableRow}>
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                                className={classes.text}
                                animation={'wave'}
                              />
                            </Grid>
                            <Grid item sm={3} className={classes.tableRow}>
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                                className={classes.text}
                                animation={'wave'}
                              />
                            </Grid>
                            <Grid item sm={3} className={classes.tableRow}>
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                                className={classes.text}
                                animation={'wave'}
                              />
                            </Grid>
                            <Grid item sm={2} className={classes.tableRow}>
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                                className={classes.text}
                                animation={'wave'}
                              />
                            </Grid>
                            <Grid item sm={2} className={classes.tableRow}>
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                                className={classes.text}
                                animation={'wave'}
                              />
                            </Grid>
                          </React.Fragment>
                        ))}
                    </>
                  ) : (
                    <>
                      {Array(5)
                        .fill({})
                        .map((_, j) => (
                          <React.Fragment key={j}>
                            {Array(3)
                              .fill({})
                              .map((_, i) => (
                                <Grid
                                  item
                                  sm={4}
                                  className={classes.gridRowParent}
                                  key={i}
                                >
                                  <div className={classes.gridRow}>
                                    <Skeleton
                                      variant="rect"
                                      width="60%"
                                      height={130}
                                      animation={'wave'}
                                      style={{ marginBottom: 12 }}
                                    />
                                    <Skeleton
                                      variant="text"
                                      width="100%"
                                      height={30}
                                      animation={'wave'}
                                    />
                                    <Skeleton
                                      variant="text"
                                      width="100%"
                                      height={30}
                                      animation={'wave'}
                                    />
                                    <Skeleton
                                      variant="text"
                                      width="100%"
                                      height={30}
                                      animation={'wave'}
                                      style={{ marginBottom: 12 }}
                                    />
                                    <Skeleton
                                      variant="text"
                                      width="100%"
                                      height={60}
                                      animation={'wave'}
                                    />
                                  </div>
                                </Grid>
                              ))}
                          </React.Fragment>
                        ))}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* ))} */}
        </Grid>
      </Box>
    </>
  ) : (
    <>
      <Typography variant="h2">Products</Typography>
      <Grid container>
        {Array(10)
          .fill({})
          .map((_, i) => (
            <Grid item xs={12} className={classes.tableRow} key={i}>
              {Array(4)
                .fill({})
                .map((_, j) => (
                  <React.Fragment key={j}>
                    <Grid item sm={3} className={classes.mGridText}>
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={30}
                        className={classes.text}
                        animation={'wave'}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          ))}
      </Grid>
    </>
  )
}

export default CSRSkeletonLoader
